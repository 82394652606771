/* tslint:disable:variable-name function-name */
import { BaseClass, createAsyncSaver, ReduxEndpoint } from 'reports/utils/api';
import moment from 'moment';

import { DeepPartial } from 'reports/types';
import { ICurrency, ILocale } from 'reports/localization';

import * as theme from './theme';
import * as usr from './user';
import { schema } from './schema';
import { Subscription } from './subscription';
import { WeatherSourceType } from './weather_source';
import { TeamFraudEvent } from './team_fraud_event';

enum SubscriptionType {
    none = 'none',
    v1_1_to_1 = 'v1_1_to_1',
    v1_non_1_to_1 = 'v1_non_1_to_1',
    v1_expired = 'v1_expired',
    v2_1_to_1 = 'v2_1_to_1',
    v2_expired = 'v2_expired',
    v2_contract = 'v2_contract',
}

export enum ConsumptionEnforcementPeriod {
    immediately = 'immediately',
    phase_iv_and_v = 'phase_iv_and_v',
    one_year_grace_period = 'one_year_grace_period',
    no_enforcement = 'no_enforcement',
}

interface IProjectExportSettings {
    design_render: boolean;
    design_layout_cad: boolean;
    design_sld: boolean;
    sim_hourly_results: boolean;
    report_ids: number[];
}

export interface SelfServeSettings {
    admin_allowance_project_count?: number;
    consumption_enforcement_period: ConsumptionEnforcementPeriod | null;
}

function defaultExportSettings(reports): IProjectExportSettings {
    return {
        design_layout_cad: true,
        design_render: true,
        design_sld: true,
        sim_hourly_results: true,
        // export all reports by default
        report_ids: reports.map((report) => report.report_id),
    };
}

class Team extends BaseClass {
    team_id: number;
    name: string;
    share_projects: boolean;
    theme_id: number;
    theme: theme.Theme;

    users: usr.User[];

    // Team feature flag properties. These are not be to used to check if a team
    // is enabled into a feature, since they do not take experiment rollout % into
    // account. For that, use the user.hasFeature method.
    features: { [key: string]: boolean };

    currency: ICurrency;
    locale: ILocale;
    preferred_weather_source_type: WeatherSourceType | null;
    preferred_weather_source_radius: number | null;
    project_export_settings: IProjectExportSettings | null;

    scf_company_id?: string;

    is_on_custom_plan: boolean;
    custom_plan_end: moment.Moment;

    should_upsell_consumption: boolean;
    should_enforce_consumption: boolean;

    trial_project_limit: number | null;

    stripe_customer_id: string;
    can_purchase_subscription: boolean;
    has_active_subscription: boolean;
    num_active_subscriptions: number;
    subscription_version: number;
    subscription_type: SubscriptionType;
    formatted_billing_address: string[];
    // latest_subscription will not exist for teams that have multiple active/pending subscriptions (AKA non 1-to-1
    // teams). To see the subscriptions for a non-1-to-1 team, look at individual users' User.latest_subscription.
    latest_subscription: Subscription | null;

    admin_allowance_project_count?: number;
    consumption_enforcement_period: ConsumptionEnforcementPeriod | null;

    pay_by_invoice_enabled: boolean;
    team_fraud_events: TeamFraudEvent[];
    /**
     * @deprecated: use Team.latest_subscription instead. Team.subscription has undefined behavior for non-1-to-1 teams.
     */
    get subscription() {
        // The current data model doesn't support the assumption that all users
        // on a team belong to the same subscription, but it's an underlying assumption
        // we're working with and a reality we're building toward with the data model.
        const user = this.users.find((user) => user.subscription != null);
        return user ? user.subscription : null;
    }

    constructor(data) {
        super(Team.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        custom_plan_end: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(Team, 'team');
export const endpoint = ReduxEndpoint.fromSchema('/api/teams/', schemaObj);

const { selector: themeSelector } = schemaObj.addRelationship('theme', theme.schemaObj);

export interface IUserTeamAdminForm {
    team_id: number;
    email: string;
    team_admin: boolean;
}

export type ITeamForm = DeepPartial<Team>;

export interface TeamNameForm {
    name: string;
}

export type IProjectUsage = {
    project_limit: number;
    project_count: number;
};

export type ITeamLimitsAndUsage = {
    team_id: number;
    project_count: number;
    trial_project_limit: number;
    trial_limits: IProjectUsage | null;
    subscription_limits: IProjectUsage | null;
    admin_limits: number | null;
};

type RawPeriodUsage = {
    project_count: number;
    period_start: string;
};

type RawTeamHistoricUsage = {
    periods: RawPeriodUsage[];
};

type PeriodUsage = {
    project_count: number;
    period_start: moment.Moment;
};

type LegacyInvoiceUrl = {
    download_url: string | null;
};

export type TeamHistoricUsage = {
    periods: PeriodUsage[];
};

const api = {
    index: endpoint.index(),
    get: endpoint.get<{ team_id: number | string }>('{team_id}'),
    save: endpoint.put<ITeamForm>('{team_id}'),
    delete: endpoint.delete('{team_id}'),
    /** Ideally updateUser would be configured such that the user returned
     * from the endpoint is updated in the Redux store. We could do this by
     * defining the config onSuccess value to be the User schema dataLoaded
     * action and the config selector value to be the User byObject selector.
     * However, this introduces a cyclic dependency between the User and Team
     * models so we cannot configure it to do this at this moment.
     */
    updateUser: endpoint.put<IUserTeamAdminForm, any, DeepPartial<usr.User>>(
        '{team_id}/update_user',
        ReduxEndpoint.PassThroughConfig(),
    ),
    updateName: endpoint.put<TeamNameForm, { team_id: number }>('{team_id}/name'),
    mergeTeam: endpoint.put<
        {
            team_to_merge_id: number;
        },
        { team_id: number }
    >('{team_id}/merge'),
    updateProjectExportSettings: endpoint.put<IProjectExportSettings, { team_id: number | string; as_user?: string }>(
        '{team_id}/project_export_settings',
    ),
    updateSelfServeSettings: endpoint.put<SelfServeSettings, { team_id: number | string }>(
        '{team_id}/self_serve_settings',
    ),
    updateTrialSettings: endpoint.put<{ trial_project_limit: number | null }, { team_id: number | string }>(
        '{team_id}/trial_settings',
    ),
    addUser: endpoint.patch<{ user_id: number }, { team_id: number }>('{team_id}/users'),

    getLimitsAndUsage: endpoint.get<{ team_id: number | string }, ITeamLimitsAndUsage>(
        '{team_id}/limits_and_usage',
        ReduxEndpoint.PassThroughConfig(),
    ),
    getHistoricUsage: endpoint.get<{ team_id: number | string }, TeamHistoricUsage>('{team_id}/historic_usage', {
        ...ReduxEndpoint.PassThroughConfig(),
        selector: (_state, rawData: RawTeamHistoricUsage) => ({
            periods: rawData.periods.map((rawPeriod) => ({
                period_start: moment(rawPeriod.period_start),
                project_count: rawPeriod.project_count,
            })),
        }),
    }),
    updateTeamFraud: endpoint.put<{ team_id: number }>('{team_id}/team_fraud'),
    getLegacyInvoiceUrl: endpoint.get<{ team_id: number }, LegacyInvoiceUrl>(
        '{team_id}/legacy_invoice_url',
        ReduxEndpoint.PassThroughConfig(),
    ),
};

const saver = createAsyncSaver(schemaObj, api.save);

const selectors = {
    themeSelector,

    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

export { Team, IProjectExportSettings, defaultExportSettings, api, saver, schemaObj, selectors, SubscriptionType };
