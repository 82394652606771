import * as THREE from 'three';

import { user } from 'helioscope/app/users';
import { Vector, Bounds, pathOrientation, clampRadians } from 'helioscope/app/utilities/geometry';
import { arrayItemWrap } from 'helioscope/app/utilities/helpers';
import { FieldSegmentEdgeMenu, getPathLowPoint } from 'helioscope/app/designer/field_segment';
import { RendererOptions } from './RendererOptions';
import {
    containerPoint,
    createContextMenu,
    hitTestRendererObjects,
    publishUpdatePath,
    drawHandleCircleFill,
    drawHandleCircleStroke,
} from './InteractHelpers';
import {
    DragActionEditPhysicalSurfaceMoveVertex,
    DragActionEditPhysicalSurfaceCreateVertex,
} from './InteractGeometry';
import {
    PrimitiveMeshStroke,
    PrimitiveMeshFill,
    PrimitiveTextTexture,
} from './Primitives';

function formattedDistance(len) {
    const unit = user.preferences.units.distance || 'm';
    let conversion = 1.0;
    if (unit === 'ft') {
        conversion = 3.28084;
    }

    const displaylen = (len * conversion).toFixed(1);
    return `${displaylen} ${unit}`;
}

function showSurfaceEdgeContextMenu(widget, event) {
    const { dispatcher } = widget.renderer;
    const pt = containerPoint(widget.renderer, event);
    const hitTest = hitTestRendererObjects(widget.renderer, pt);
    const ctxmenu = _.assign({},
        FieldSegmentEdgeMenu,
        { locals:
            _.assign({
                location: new Vector(hitTest.intersectPoint),
                averagePosition: {},
                fieldSegment: widget.surface,
                dispatcher,
            },
            widget.surface.getEdgeInfo(widget.pathIndex)),
        });
    createContextMenu(widget.renderer, ctxmenu, ({ x: pt.x - 15, y: pt.y - 10 }));
}

function middlePoint(vecA, vecB) {
    return (new THREE.Vector3())
        .addVectors(vecA, vecB)
        .multiplyScalar(0.5);
}

// TODO: MT: eventually edges and vertices may be promoted to first class geometry objects
// with renderables that are child renderables of surfaces and not just be widgets
export class WidgetSurfaceCollection {
    constructor(renderer, surface, surfaceType) {
        this.renderer = renderer;
        this.surface = surface;
        this.surfaceType = surfaceType;
    }

    createWidget(options) {
        this.renderableWidgets = [];

        const { renderer, surface, surfaceType } = this;

        // True if the signed area of path is non-negative (the points are counter clockwise)
        const orientation = pathOrientation(surface.geometry.path);

        if (options.dragHandles) {
            // move vertex on ps path
            for (let idx = 0; idx < surface.geometry.path_3d.length; idx++) {
                const widget = new WidgetSurfaceVertexHandle(renderer, surface, surfaceType, idx);
                widget.createWidget();
                this.renderableWidgets.push(widget);
            }

            // add vertex to ps path edge
            for (let idx = 0; idx < surface.geometry.path_3d.length; idx++) {
                const widget = new WidgetSurfaceEdgeHandle(renderer, surface, surfaceType, idx);
                widget.createWidget();
                this.renderableWidgets.push(widget);
            }
        }

        if (options.edgeLabels) {
            let drawOptions = {
                scaling: user.preferences.designer.label_scale_factor || 1,
                parallelToEdge: true
            };

            // edge length labels
            for (let idx = 0; idx < surface.geometry.path_3d.length; idx++) {
                const edge = this.surface.getEdgeInfo(idx);
                const widget = new WidgetSurfaceEdgeLabel(renderer, surface, surfaceType, idx, drawOptions);
                widget.createWidget(orientation, edge.distance);
                this.renderableWidgets.push(widget);
            }

            // edge height label. only rendered when height is non-zero
            if (surface.referenceHeight > 0) {
                drawOptions = Object.assign({}, drawOptions, {
                    parallelToEdge: false,
                });
                const widget = new WidgetSurfaceHeightLabel(renderer, surface, surfaceType, drawOptions);
                widget.createWidget(orientation, surface.referenceHeight);
                this.renderableWidgets.push(widget);
            }
        }

        if (options.surfaceLabels) {
            // surface name labels
            const widget = new WidgetSurfaceNameLabel(renderer, surface, surfaceType);
            widget.createWidget(orientation);
            this.renderableWidgets.push(widget);
        }

        this.preframeFn = () => { this.updateWidget(); };
        this.removeUpdate = this.renderer.registerPreFrameCallback(this.preframeFn, true);
    }

    clearWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.clearWidget();
            }

            this.renderableWidgets = null;
        }

        if (this.removeUpdate) {
            this.removeUpdate();
            this.removeUpdate = null;
        }
    }

    updateWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.updateWidget(this.renderer.cameraProjectionMatrix);
            }
        }
    }
}

export class WidgetSurfaceVertexHandle {
    constructor(renderer, surface, objectType, index) {
        this.renderer = renderer;
        this.surface = surface;
        this.objectType = objectType;
        this.pathIndex = index;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetSurfaceVertexHandle',
        };

        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const renderable = this.renderer.objectRenderMap.get(this.surface);
        const pt = this.surface.geometry.path_3d[this.pathIndex];
        const mtx = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, pt);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.strokePrimitive = null;
    }

    widgetMouseDown(event) {
        if (event.button === 0) {
            this.renderer.activateDragAction(
                new DragActionEditPhysicalSurfaceMoveVertex(
                    this.renderer, event,
                    {
                        object: this.surface,
                        objectType: this.objectType,
                        pathIndex: this.pathIndex,
                    }));
            return true;
        }

        if (event.button === 2) {
            if (this.surface.geometry.path.length > 2) {
                const newPath = _.map(this.surface.geometry.path, i => Vector.fromObject(i));
                newPath.splice(this.pathIndex, 1);
                publishUpdatePath(this.renderer, this.surface, this.objectType, newPath);
            }

            return true;
        }

        return false;
    }
}

export class WidgetSurfaceEdgeHandle {
    constructor(renderer, surface, objectType, index) {
        this.renderer = renderer;
        this.surface = surface;
        this.objectType = objectType;
        this.pathIndex = index;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetSurfaceEdgeHandle',
        };

        const options = _.assign({}, RendererOptions.edgeHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const renderable = this.renderer.objectRenderMap.get(this.surface);
        const pta = arrayItemWrap(
            this.surface.geometry.path_3d, this.pathIndex);
        const ptb = arrayItemWrap(
            this.surface.geometry.path_3d, this.pathIndex + 1);
        const pt =
            (new THREE.Vector3())
            .addVectors(pta, ptb)
            .multiplyScalar(0.5);
        const mtx = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, pt);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.strokePrimitive = null;
    }

    widgetMouseDown(event) {
        if (event.button === 0) {
            this.renderer.activateDragAction(
                new DragActionEditPhysicalSurfaceCreateVertex(
                    this.renderer, event,
                    {
                        object: this.surface,
                        objectType: this.objectType,
                        pathIndex: this.pathIndex,
                    }));
            return true;
        }

        if (event.button === 2) {
            if (this.objectType === 'FieldSegment') {
                showSurfaceEdgeContextMenu(this, event);
                return true;
            }
        }

        return false;
    }
}

export class WidgetSurfaceEdgeLabel {
    constructor(renderer, surface, surfaceType, index, drawOptions = { scaling: 1.0, rotation: 0.0, parallelToEdge: true }) {
        this.renderer = renderer;
        this.surface = surface;
        this.surfaceType = surfaceType;
        this.pathIndex = index;
        this.drawOptions = drawOptions;
    }

    createWidget(pathOrientation, value) {
        this.pathOrientation = pathOrientation;
        this.labelValue = value;
        this.primitives = [];

        const selectionData = {
            object: this,
            type: 'WidgetSurfaceEdgeLabel',
        };

        this.renderLabelText(selectionData, value);
        this.renderLabelQuad(selectionData);
    }

    renderLabelText(selectionData, value) {
        selectionData.noRaycast = true;

        const options = {
            screenSpace: true,
            text: formattedDistance(value),
            font: this.renderer.graphicResourceCache.notoSansRegularFont,
            texture: this.renderer.graphicResourceCache.notoSansRegularTexture,
            fillColor: RendererOptions.edgeLabelOptions.textFG,
            scale: this.drawOptions.scaling * RendererOptions.edgeLabelOptions.textureFontScaling,
            scene: this.renderer.interactLayer,
            renderOrder: 2,
            selectionData,
        };

        this.textPrimitive = this.renderer.renderPrimitive(PrimitiveTextTexture, options);
        this.primitives.push(this.textPrimitive);
    }

    renderLabelQuad(selectionData) {
        selectionData.noRaycast = false;

        const geometry = this.renderer.shapeBuilder.quadFill(1.0, 1.0);
        const material = this.renderer.inlineShaderMaterial('vertexShaderBasic', 'fragmentShaderBasic');

        const options = {
            geometry,
            material,
            scene: this.renderer.interactLayer,
            screenSpace: true,
            fillColor: RendererOptions.edgeLabelOptions.textBG,
            renderOrder: 1,
            selectionData,
        };

        this.quadPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, options);
        this.primitives.push(this.quadPrimitive);
    }

    clientPathVector(vec, camProjMatrix) {
        const renderable = this.renderer.objectRenderMap.get(this.surface);
        const matrix = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        return this.renderer.transformObjectMatrixToClient(matrix, camProjMatrix, vec);
    }

    updateWidget(camProjMtx) {
        const vecA = arrayItemWrap(this.surface.geometry.path_3d, this.pathIndex);
        const vecB = arrayItemWrap(this.surface.geometry.path_3d, this.pathIndex + 1);
        const vecAClient = this.clientPathVector(vecA, camProjMtx);
        const vecBClient = this.clientPathVector(vecB, camProjMtx);

        // depending on the path orientation, swap two points to ensure label renders on the outside of the edge
        if (!this.pathOrientation) {
            [vecAClient, vecBClient] = [vecBClient, vecAClient];
        }

        this.updateWidgetRotation(vecAClient, vecBClient);

        const edgeVectorMiddle = middlePoint(vecAClient, vecBClient);
        this.transformWidgetText(edgeVectorMiddle);
        this.transformWidgetQuad(edgeVectorMiddle);
    }

    updateWidgetRotation(clientVecA, clientVecB) {
        const edgeVectorNormal = (new THREE.Vector2())
            .subVectors(clientVecB, clientVecA)
            .normalize();

        let rotation = clampRadians(Math.atan2(edgeVectorNormal.y, edgeVectorNormal.x));
        // when drawOptions.parallelToEdge is false, the text and quad backing should be orthogonal to the edge
        if (!this.drawOptions.parallelToEdge) {
            rotation += Math.PI / 2;
        }

        this.drawOptions.rotation = rotation;
    }

    /**
     * Set top left point of text, put in middle of edge,
     * and offset to the outside
     */
    transformWidgetText(middlePt) {
        const { rotation } = this.drawOptions;
        // perpendicular offset away from actual edge midpoint at which to render label
        const edgeOffset = RendererOptions.edgeLabelOptions.offset;

        // determine whether to flip the text based on the camera angle
        const textOrientation = rotation > 1.5 * Math.PI || rotation < 0.5 * Math.PI;
        const textRotation = textOrientation ? rotation : rotation - Math.PI;

        // rotational transformation to the z-axis of the text primitive
        const textEuler = new THREE.Euler(0, 0, textRotation);
        const textRotationMatrix = (new THREE.Matrix4()).makeRotationFromEuler(textEuler);

        const offset = textOrientation ?
            new THREE.Vector3(-this.textPrimitive.boundingBox.max.x * 0.5,
                edgeOffset + this.textPrimitive.boundingBox.max.y, 0) :
            new THREE.Vector3(-this.textPrimitive.boundingBox.max.x * 0.5,
                -edgeOffset, 0);

        // apply rotational transformation to the offset vector
        offset.applyMatrix4(textRotationMatrix);

        const textPosition = (new THREE.Vector3()).addVectors(middlePt, offset);
        this.textPrimitive.setRenderRotation(textEuler);
        this.textPrimitive.setRenderPosition(textPosition);
    }

    /**
     * Set center point of the quad backing the text, put in middle of edge,
     * and offset to the outside
     */
    transformWidgetQuad(middlePt) {
        const { rotation } = this.drawOptions;
        // perpendicular offset away from actual edge midpoint at which to render label
        const edgeOffset = RendererOptions.edgeLabelOptions.offset;

        // quad dimensions
        const quadPadding = RendererOptions.edgeLabelOptions.bgPadding;
        const quadHeight = this.textPrimitive.boundingBox.max.y + quadPadding * 2;
        const quadWidth = this.textPrimitive.boundingBox.max.x + quadPadding * 2;

        // rotational transformation to the z-axis of the quad primitive
        const quadEuler = new THREE.Euler(0, 0, rotation);
        const quadRotationMatrix = (new THREE.Matrix4()).makeRotationFromEuler(quadEuler);

        const offset = new THREE.Vector3(0, edgeOffset + quadHeight * 0.5 - quadPadding, 0);
        // apply rotational transformation to the offset vector
        offset.applyMatrix4(quadRotationMatrix);

        const quadPosition = (new THREE.Vector3()).addVectors(middlePt, offset);
        this.quadPrimitive.setRenderScale(new THREE.Vector3(quadWidth, quadHeight, 1.0));
        this.quadPrimitive.setRenderRotation(quadEuler);
        this.quadPrimitive.setRenderPosition(quadPosition);
    }

    clearWidget() {
        this.textPrimitive.clearInstances();
        this.textPrimitive = null;
        this.quadPrimitive.clearInstances();
        this.quadPrimitive = null;
        this.primitives = [];
    }

    widgetMouseDown(event) {
        if (event.button === 2) {
            if (this.surfaceType === 'FieldSegment') {
                showSurfaceEdgeContextMenu(this, event);
                return true;
            }
        }

        return false;
    }
}

export class WidgetSurfaceHeightLabel extends WidgetSurfaceEdgeLabel {
    constructor(renderer, surface, surfaceType, drawOptions) {
        // call parent constructor with index = null since there is only one height label per surface
        const index = null;
        super(renderer, surface, surfaceType, index, drawOptions);
    }

    updateWidget(camProjMtx) {
        // label should only render on the shortest edge of the surface
        const pathLowPointSurface = getPathLowPoint(this.surface.geometry.path_3d, this.surface.surfaceAzimuth());
        // subtract the reference height to get the base level point, which can be non-zero
        // if the surface is stacked on top of another
        const pathLowPointStart = new Vector(pathLowPointSurface.x, pathLowPointSurface.y, pathLowPointSurface.z - this.surface.referenceHeight);

        let pathLowPointStartClient = this.clientPathVector(pathLowPointStart, camProjMtx);
        let pathLowPointSurfaceClient = this.clientPathVector(pathLowPointSurface, camProjMtx);

        // depending on orientation, swap two points to ensure label renders on the outside
        if (!this.pathOrientation) {
            [pathLowPointStartClient, pathLowPointSurfaceClient] = [pathLowPointSurfaceClient, pathLowPointStartClient];
        }

        this.updateWidgetRotation(pathLowPointStartClient, pathLowPointSurfaceClient);

        // only display widgets when the camera is not in top-down view.
        // this results in a rotation value of Pi or greater
        if (this.drawOptions.rotation >= Math.PI) {
            // show widget if previously hidden
            this.showWidget();
            const edgeVectorMiddle = middlePoint(pathLowPointStartClient, pathLowPointSurfaceClient);
            this.transformWidgetText(edgeVectorMiddle);
            this.transformWidgetQuad(edgeVectorMiddle);
        } else if (this.drawOptions.rotation < Math.PI) {
            // hide widget in top-down view
            this.hideWidget();
        }
    }

    showWidget() {
        if (this.textPrimitive.glInstance && !this.textPrimitive.glInstance.visible) {
            this.textPrimitive.glInstance.visible = true;
        }

        if (this.quadPrimitive.glInstance && !this.quadPrimitive.glInstance.visible) {
            this.quadPrimitive.glInstance.visible = true;
        }
    }

    hideWidget() {
        if (this.textPrimitive.glInstance && this.textPrimitive.glInstance.visible) {
            this.textPrimitive.glInstance.visible = false;
        }

        if (this.quadPrimitive.glInstance && this.quadPrimitive.glInstance.visible) {
            this.quadPrimitive.glInstance.visible = false;
        }
    }

    widgetMouseDown(event) {
        return false;
    }
}

export class WidgetSurfaceNameLabel {
    constructor(renderer, surface, objectType) {
        this.renderer = renderer;
        this.surface = surface;
        this.objectType = objectType;
    }

    createWidget(orientation) {
        this.orientation = orientation;
        this.primitives = [];

        const selectionData = {
            object: this,
            type: 'WidgetSurfaceNameLabel',
        };

        this.renderLabelText(selectionData);
        this.renderLabelQuad(selectionData);
    }

    renderLabelText(selectionData) {
        selectionData.noRaycast = true;

        const options = {
            screenSpace: true,
            text: this.surface.description,
            font: this.renderer.graphicResourceCache.notoSansRegularFont,
            texture: this.renderer.graphicResourceCache.notoSansRegularTexture,
            fillColor: RendererOptions.surfaceLabelOptions.textFG,
            scale: RendererOptions.surfaceLabelOptions.textureFontScaling,
            scene: this.renderer.interactLayer,
            depthOffset: this.renderer.tinyZOffset,
            renderOrder: 2,
            selectionData,
        };

        this.textPrimitive = this.renderer.renderPrimitive(PrimitiveTextTexture, options);
    }

    renderLabelQuad(selectionData) {
        selectionData.noRaycast = false;

        const geometry = this.renderer.shapeBuilder.quadFill(1.0, 1.0);
        const material = this.renderer.inlineShaderMaterial('vertexShaderBasic', 'fragmentShaderBasic');

        const options = {
            geometry,
            material,
            scene: this.renderer.interactLayer,
            screenSpace: true,
            fillColor: RendererOptions.surfaceLabelOptions.textBG,
            renderOrder: -10,
            selectionData,
        };

        this.quadPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, options);
    }

    updateWidget(camProjMtx) {
        const renderable = this.renderer.objectRenderMap.get(this.surface);
        const midPoint = Bounds.pathMidPoint(this.surface.geometry.path);
        const objPoint = this.surface.pointOnSurface(midPoint).addXYZ(0, 0, 0.1);
        const mtx = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        const pt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, objPoint);

        const rot = 0; // toRadians(this.surface.azimuth !== undefined ? this.surface.azimuth - 180 : 0);

        // perpendicular offset away from actual edge midpoint at which to render label
        const edgeOffset = RendererOptions.edgeLabelOptions.offset;

        {
            // set top left point of text, put in middle of edge, offset to the outside
            const textOrientation = true;
            const textRot = rot;
            const textEuler = new THREE.Euler(0, 0, textRot);
            const textMtx = (new THREE.Matrix4()).makeRotationFromEuler(textEuler);

            const offset = textOrientation ?
                new THREE.Vector3(-this.textPrimitive.boundingBox.max.x * 0.5,
                    edgeOffset + this.textPrimitive.boundingBox.max.y, 0) :
                new THREE.Vector3(-this.textPrimitive.boundingBox.max.x * 0.5,
                    -edgeOffset, 0);
            offset.applyMatrix4(textMtx);
            const clientPt = (new THREE.Vector3()).addVectors(pt, offset);
            this.textPrimitive.setRenderRotation(textEuler);
            this.textPrimitive.setRenderPosition(clientPt);
        }

        {
            // quad backing text
            const quadPadding = RendererOptions.edgeLabelOptions.bgPadding;
            const quadHeight = this.textPrimitive.boundingBox.max.y + quadPadding * 2;
            const quadWidth = this.textPrimitive.boundingBox.max.x + quadPadding * 2;

            const quadRot = new THREE.Euler(0, 0, rot);
            const quadMtx = (new THREE.Matrix4()).makeRotationFromEuler(quadRot);

            // set center point of quad, put in middle of edge, offset to the outside
            const offset = new THREE.Vector3(0, edgeOffset + quadHeight * 0.5 - quadPadding, 0);
            offset.applyMatrix4(quadMtx);
            const clientPt = (new THREE.Vector3()).addVectors(pt, offset);
            this.quadPrimitive.setRenderScale(new THREE.Vector3(quadWidth, quadHeight, 1.0));
            this.quadPrimitive.setRenderRotation(quadRot);
            this.quadPrimitive.setRenderPosition(clientPt);
        }
    }

    clearWidget() {
        this.textPrimitive.clearInstances();
        this.textPrimitive = null;
        this.quadPrimitive.clearInstances();
        this.quadPrimitive = null;
    }

    widgetMouseDown() {
    }
}
