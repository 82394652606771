import * as THREE from 'three';

import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { actionPremadeDelete } from 'helioscope/app/designer/premade/actions';

import { RendererOptions } from 'helioscope/app/apollo/RendererOptions';
import { DragActionPremadeChangeProperty } from './InteractGeometry';
import { drawHandleCircleFill, drawHandleCircleStroke } from './InteractHelpers';

export class WidgetSphereTreeCollection {
    constructor(renderer, premade) {
        this.renderer = renderer;
        this.premade = premade;
    }

    createWidget() {
        this.renderableWidgets = [];

        const widgetTopRadius = new WidgetSphereTreeTopRadius(this.renderer, this.premade);
        widgetTopRadius.createWidget();
        this.renderableWidgets.push(widgetTopRadius);

        const widgetBotHeight = new WidgetSphereTreeTopHeight(this.renderer, this.premade);
        widgetBotHeight.createWidget();
        this.renderableWidgets.push(widgetBotHeight);

        this.preframeFn = () => { this.updateWidget(); };
        this.removeUpdate = this.renderer.registerPreFrameCallback(this.preframeFn, true);
    }

    clearWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.clearWidget();
            }

            this.renderableWidgets = null;
        }

        if (this.removeUpdate) {
            this.removeUpdate();
            this.removeUpdate = null;
        }
    }

    updateWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.updateWidget(this.renderer.cameraProjectionMatrix);
            }
        }
    }
}

class WidgetSphereTreeTopRadius {
    constructor(renderer, premade) {
        this.renderer = renderer;
        this.premade = premade;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetSphereTreeTopRadius',
        };

        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const { position, position_3d, top_radius, bot_height } = this.premade.geometry.parameters;
        const basePoint = position_3d || position;

        const pt = (new THREE.Vector3(basePoint.x, basePoint.y, basePoint.z + bot_height + top_radius))
            .add((new THREE.Vector3())
                .copy(this.renderer.cameraWorldRight)
                .multiplyScalar(top_radius));
        const clientPt = this.renderer.transformObjectMatrixToClient(new THREE.Matrix4(), camProjMtx, pt);

        this.worldPoint = pt;

        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.strokePrimitive = null;
    }

    widgetMouseDown(event) {
        if (event.button === 0) {
            this.renderer.activateDragAction(
                new DragActionPremadeChangeProperty(
                    this.renderer, event,
                    {
                        object: this.premade,
                        worldPoint: this.worldPoint,
                        property: 'geometry.parameters.top_radius',
                        minValue: EntityPremade.MIN_TOP_RADIUS,
                        maxValue: EntityPremade.MAX_TOP_RADIUS,
                        direction: 'right',
                    }));
            return true;
        }

        if (event.button === 2) {
            actionPremadeDelete({
                dispatcher: this.renderer.dispatcher,
                premade: this.premade,
            });
            return true;
        }

        return false;
    }
}

class WidgetSphereTreeTopHeight {
    constructor(renderer, premade) {
        this.renderer = renderer;
        this.premade = premade;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetSphereTreeTopHeight',
        };

        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const { topHeight } = this.premade.proxyProperties;
        const { position, position_3d } = this.premade.geometry.parameters;
        const basePoint = position_3d || position;

        const pt = new THREE.Vector3(basePoint.x, basePoint.y, basePoint.z + topHeight);
        const clientPt = this.renderer.transformObjectMatrixToClient(new THREE.Matrix4(), camProjMtx, pt);

        this.worldPoint = pt;

        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.strokePrimitive = null;
    }

    widgetMouseDown(event) {
        if (event.button === 0) {
            this.renderer.activateDragAction(
                new DragActionPremadeChangeProperty(
                    this.renderer, event,
                    {
                        object: this.premade,
                        worldPoint: this.worldPoint,
                        property: 'proxyProperties.topHeight',
                        minValue: EntityPremade.MIN_BOT_HEIGHT,
                        maxValue: EntityPremade.MAX_BOT_HEIGHT,
                        direction: 'up',
                    }));
            return true;
        }

        if (event.button === 2) {
            actionPremadeDelete({
                dispatcher: this.renderer.dispatcher,
                premade: this.premade,
            });
            return true;
        }

        return false;
    }
}
